// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-next-page-js": () => import("./../../../src/pages/next-page.js" /* webpackChunkName: "component---src-pages-next-page-js" */),
  "component---src-pages-product-page-js": () => import("./../../../src/pages/product-page.js" /* webpackChunkName: "component---src-pages-product-page-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-video-page-js": () => import("./../../../src/pages/video-page.js" /* webpackChunkName: "component---src-pages-video-page-js" */)
}

